import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";
import image5 from "../assets/t-image4.jpg";
import defImage from "../assets/defImage.jpg";

export const testimonialsData = [
  {
    image: defImage,
    review:
      "Nedavno sam unajmio opremu za svoj ugostiteljski projekt od ove tvrtke za iznajmljivanje i bio sam oduševljen kvalitetom opreme i razinom usluge koju sam dobio. Oprema je bila u izvrsnom stanju, a osoblje mi je bilo od velike pomoći pri odabiru prave opreme za moj party. Toplo preporučujem ovu tvrtku za iznajmljivanje svima kojima je potrebna pouzdana i visokokvalitetna oprema.",
    name: 'Mario V.',
    status : 'Ugostitelj'
  },
  {
    image: defImage,
    review: "Otvorio sam malu tvrtku i trebao sam unajmiti nešto opreme za uzvanike prilikom otvaranja. U početku sam bio neodlučan, ali ova tvrtka za iznajmljivanje nadmašila je moja očekivanja. Oprema je bila dobro održavana i jednostavna za korištenje, a proces najma tekao je glatko i bez ikakvih problema. Osoblje je bilo ljubazno i uslužno, i cijenio sam njihovu fleksibilnost u prilagođavanju mom rasporedu. Svakako bih preporučio ovu tvrtku za iznajmljivanje drugim vlasnicima malih tvrtki.",
    name: 'Ante B.',
    status: 'Građevinar'
  },
  {
    image : defImage,
    review:"Oprema je bila čista i dobro održavana. Isporučili su opremu i sve je savršeno funkcioniralo. Definitivno bih ponovno koristio ovu tvrtku za iznajmljivanje za svoje potrebe.",
    name: 'Frane',
    status: "Ugostitelj"
  },
  {
    image : defImage,
    review:' Često uzimam opremu za svoje projekte i nikada nisam bio razočaran, te ih toplo preporučujem',
    name: 'Jure B.',
    status: "Organizator"
  },
  {
    image : defImage,
    review:' Dugo sam koristio reklamne panoe za svoje firme i uvijek je to bila odlična investicija',
    name: 'Ivo A.',
    status: "Trgovac"
  },
];