import logo from "./logo.svg";
import "./App.css";
import Hero from "./components/Hero/Hero";
import Offers from "./components/Offers/Offers";
import Reasons from "./components/Reasons/Reasons";
import Plans from "./components/Plans/Plans";
import Testimonials from "./components/Testimonials/Testimonials";
import Join from "./components/Join/Join";
import Footer from "./components/Footer/Footer";
import Advertising from "./container/Advertising/Advertising";
import Rental from "./container/Rental/Rental";
import ScrollToTop from "react-scroll-to-top";
import Partneri from "./container/Partneri/Partneri";
import Vizuali from "./container/Vizuali/Vizuali";

function App() {
  return (
    <div className="App">
      <Hero />
      <Offers />
      {/*<Reasons />*/}
      {/*<Plans />*/}
      <Advertising />
      <Vizuali/>
      {/*<Rental />*/}
      <Partneri/>
      <Testimonials />
      <Join />
      <Footer />
      <ScrollToTop smooth />
    </div>
  );
}

export default App;
