import { images } from "../constants";
export const programsData = [
  {
    image: <img src={images.web} alt="web" />,
    heading: "Vanjsko Oglašavanje",
    details:
      "Naša tvrtka specijalizirana je za vanjsko oglašavanje. Pružamo usluge promocije na otvorenim površinama kako biste dosegnuli širu publiku.",
  },
  {
    image: <img src={images.programming} alt="web" />,
    heading: "Promjena Vizuala",
    details:
      "Obavljamo usluge promjene vizuala cerada na bilbordima, osiguravajući svježi i upečatljiv izgled kako bi vaša poruka privukla pažnju prolaznika.",
  },
  {
    image: <img src={images.advertisment} alt="web" />,
    heading: "Transporti",
    details:
      "Izvršavamo transport stvari kombi vozilima na lokalnim i udaljenim lokacijama. Brza, pouzdana i sigurna usluga za sve vaše transportne potrebe.",
  },
  {
    image: <img src={images.lookingFor} alt="web" />,
    heading: "Transferi",
    details:
      "Pružamo profesionalne usluge transfera osoba kombi vozilima. Siguran, udoban i prilagođen prijevoz na lokalnim i udaljenim lokacijama.",
  },
];
