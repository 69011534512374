import React, {useState} from 'react'
import "./Header.css"
import {images} from "../../constants"

const Header = () => {

  const mobile = window.innerWidth <=768 ? true: false;
  const [menuOpened, setMenuOpened] = useState (false);
  return (
    <div className='header'>
      {/* <img src={images.logo} alt="logo" className='logo'/> */}


      {(menuOpened===false && mobile===true)?(
        <div
        style={{backgroundColor:"var(--appColor)",
                padding: "0.5rem",
                borderRadius:"5px"}}>
          <img src={images.bars} 
          alt="" 
          style={{width:"1.5rem", height:"1.5rem"}}
          onClick={()=>setMenuOpened(true)}/>
        </div>
          ):<ul className="header__links">
          {['home', "ponude","Oglašavanje", 'Vizuali', 'Logistika', "Partneri", "Kontakt"].map((item) => (
              <li className="links" key={`link-${item}`}
              onClick={()=>setMenuOpened(false)}>
              <div />
              <a href={`#${item}`}>{item}</a>
              </li>
            ))}
          </ul>}
    </div>
  )
}

export default Header