import React, { useState } from "react";
import './Vizuali.css';
import { AppWrap } from '../../wrapper';

import Slika1Prije from './Vizuali/pantan1.jpg';
import Slika1Poslije from './Vizuali/pantan2.jpg';
import Slika2Prije from './Vizuali/industriska1.jpg';
import Slika2Poslije from './Vizuali/industriska2.jpg';

import Slika3Prije from './Vizuali/KastelaPlodine1A.jpg';
import Slika3Poslije from './Vizuali/KastelaPlodine1B.jpg';
import Slika4Prije from './Vizuali/KastelaPlodine2A.jpg';
import Slika4Poslije from './Vizuali/KastelaPlodine2B.jpg';


const Slider = ({ beforeImage, afterImage }) => {
  const [position, setPosition] = useState(50); // Početna pozicija klizača

  const handleSlide = (event) => {
    const newPosition = event.target.value;
    setPosition(newPosition);
  };

  return (
    <div className="Slider">
      <div className="Slider__images">
        <img src={beforeImage} alt="Before" style={{ width: `${position}%` }} />
        <img src={afterImage} alt="After" style={{ width: `${100 - position}%` }} />
      </div>
      <input
        type="range"
        min="0"
        max="100"
        value={position}
        onChange={handleSlide}
        className="Slider__input"
      />
    </div>
  );
};

const Advertising = () => {
  return (
    <div className='Advertising' id='advertising'>
      <div className='Offers__header'>
        <span className='stroke-text'>Vizuali</span>
        <span>Print  &  Promjena</span>
      </div>
      <div className="Poruka">
        Dobrodošli u 22Red d.o.o., vašeg partnera za svježi vizual i vrhunski ispis cerada na billboardima. Transformiramo prostore oglašavanja brzo i kvalitetno. Kontaktirajte nas danas!
      </div>
      <div className='Advertising__cards'>
        <div className='Advertising__card'>
          <Slider beforeImage={Slika1Prije} afterImage={Slika1Poslije} />
          <div className='Advertising__card-content'>
            <h3></h3>
            <p></p>
          </div>
        </div>
        <div className='Advertising__card'>
          <Slider beforeImage={Slika2Prije} afterImage={Slika2Poslije} />
          <div className='Advertising__card-content'>
            <h3></h3>
            <p></p>
          </div>
        </div>

        <div className='Advertising__card'>
          <Slider beforeImage={Slika3Prije} afterImage={Slika3Poslije} />
          <div className='Advertising__card-content'>
            <h3></h3>
            <p></p>
          </div>
        </div>

        <div className='Advertising__card'>
          <Slider beforeImage={Slika4Prije} afterImage={Slika4Poslije} />
          <div className='Advertising__card-content'>
            <h3></h3>
            <p></p>
          </div>
        </div>
        {/* Dodaj još kartica za ostale slike */}
      </div>
    </div>
  );
};

export default AppWrap(Advertising, 'Oglašavanje');
