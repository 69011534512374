import React from 'react'
import Header from '../Header/Header'
import "./Hero.css"
import {images} from "../../constants"
import {motion} from "framer-motion"
import NumberCounter from "number-counter"
import { AppWrap} from '../../wrapper';


const Hero = () => {
  const transition = {type:"spring", duration:3}
  return (
    <div className='proba'>
    <div className='blur hero-blur'></div>
    {/*lef side------------------- */}
    
      <div className='hero__left'>
        <Header />
      {/*the best.....------------ */}
      <div className='thebest'>
        <motion.div
          initial={{left:"238px"}}
          whileInView={{left:"8px"}}
          transition={{...transition, type:"tween"}}
          ></motion.div>    
        <span>we help you improve your business</span>
      </div>
      
      {/*hero heading--------------- */}
      <div className='hero__text'>
        <div>
          <span className='stroke-text'>22</span>
          <span>Red</span>
        </div>
        <div>
          <span></span>
        </div>     
        
      {/*hero text--------------- */}
        <div>
          <span>Your partner in business</span>
        </div>     
      </div>
    {/*mali prozori------------------- */}
    <div className='brojaci'>
      <div>
        <span>
        <NumberCounter end={122} start={100} delay="4" preFix="+"/>
        </span>
        <span>customers</span>
      </div>
      <div>
        <span>
        <NumberCounter end={91} start={40} delay="4" preFix="%"/>

        </span>
        <span>customer return</span>
      </div>
      <div>
        <span>
        <NumberCounter end={50} start={0} delay="4" preFix="+"/>

        </span>
        <span>items</span>
      </div>
    </div>
    {/*hero buttons------------------- */}
    <div className='hero-buttons'>
       
      <button className='btn' onClick={() => window.location.href = 'tel:+385994588007'}>Call</button>
     
      <button className='btn'>Learn more</button>
    
    </div>
  </div>

    {/*right side------------------- */}
    {/*----------------------------- */}
    <div className='hero__right'>
    {/*
        <button className='btn'>Join Now</button>
        <motion.div
          initial={{right:"-1rem"}}
          whileInView={{right:"4rem"}}
          transition={transition}
          className='heart-rate'>
          <img src={images.heart} alt="heart"/>
          <span>Heart Rate</span><span>116 bpm</span>
        </motion.div>
        <span className='red stroke-text'>Rent Me !!!</span>
      */}
        <img src={images.b2} alt="hero_image" className='hero_image'/>
        <motion.img
          initial={{right:"11rem"}}
          whileInView={{right:"26rem"}}
          transition={transition}  
        src={images.hero_image_back} alt="hero_image_back" className='hero_image_back'/>
         
        <motion.div
        className='calories'
        initial={{right:"37rem"}}
        whileInView={{right:"28rem"}}
        transition={transition}>
        <img src={images.calories} alt="calories" />
        <div>          
        <span>Print Montaža</span>
        <span >Naruči !!!</span>        
        </div>
        </motion.div>
    </div>
  </div>
  )
}

export default AppWrap(Hero, "home")   