import React, { useState } from "react";
import { AppWrap } from '../../wrapper';
import './Partneri.css'; // Uvoz CSS datoteke
import lidl from "../../assets/partners/lidl.png";
import plodine from "../../assets/partners/plodine.png";
import konzum from "../../assets/partners/konzum.png";
import crosig from "../../assets/partners/crosig.jpg";
import papirus from "../../assets/partners/papirus.jpg";
import cistoca from "../../assets/partners/cistoca.png";
import pan from "../../assets/partners/pan.jpg";

const Partneri = () => {
  // Stanje za podatke o partnerima
  const [data, setData] = useState([
    {
      id: 1,
      name: "Lidl",
      image: lidl
    },
    {
      id: 2,
      name: "Plodine",
      image: plodine
    },
    {
      id: 3,
      name: "Konzum",
      image: konzum
    },
    {
      id: 4,
      name: "Crosig",
      image: crosig
    },
    {
      id: 5,
      name: "Papirus",
      image: papirus
    },
    {
      id: 6,
      name: "Čistoća",
      image: cistoca
    },
    {
      id: 7,
      name: "Pan",
      image: pan
    }
  ]);

  return (
    <div className='Partneri' id='partneri'>
              <div className='Offers__header'>
          <span className='stroke-text'>Naši</span>
          <span>Partneri</span>
        </div>
      <div className='Partneri__cards'>
        {data.map(partner => (
          <div key={partner.id} className='Partneri__card-image'>
            <h2 className="naslovPartnera">{partner.name}</h2>
            {/* Koristite uvezene slike */}
            <img src={partner.image} alt={partner.name} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppWrap(Partneri, 'Partneri');
